.sidebar {
  width: 280px;
  background-color: #1c1c1c;
  color: #ffffff;
  padding: 20px;
  height: calc(100vh - 60px);
  overflow-y: auto;
  font-family: 'Inter', sans-serif;
  border-right: 1px solid rgba(255, 20, 147, 0.1);
  box-shadow: inset -10px 0 20px -10px rgba(255, 20, 147, 0.05);
}

.sidebar h2 {
  margin-bottom: 20px;
  font-size: 22px;
  font-weight: 600;
  color: #ff1493;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-shadow: 0 0 10px rgba(255, 20, 147, 0.2);
  padding-bottom: 15px;
  border-bottom: 2px solid rgba(255, 20, 147, 0.1);
}

.nested-list {
  list-style-type: none;
  padding-left: 18px;
  margin: 5px 0;
  border-left: 1px solid rgba(255, 20, 147, 0.1);
}

.nested-list li {
  margin-bottom: 2px;
}

/* Shared button styles */
.sidebar-title,
.sidebar-link {
  background: none;
  border: none;
  width: 100%;
  text-align: left;
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;
  padding: 10px;
  color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.3s ease;
  border-radius: 6px;
  font-family: inherit;
}

.sidebar-title {
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.3px;
  margin-bottom: 5px;
}

.sidebar-link {
  padding: 8px 10px;
}

.link-content {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
}

/* Hover and active states */
.sidebar-title:hover,
.sidebar-link:hover {
  color: #ff1493;
  transform: translateX(5px);
  background: rgba(255, 20, 147, 0.05);
}

.sidebar-title.active,
.sidebar-link.active {
  color: #ff1493;
  font-weight: 600;
  background: rgba(255, 20, 147, 0.05);
}

/* Section icon styles */
.section-icon {
  color: rgba(255, 20, 147, 0.5);
  font-size: 12px;
  transition: transform 0.3s ease;
  margin-left: 8px;
}

.sidebar-title.active .section-icon {
  color: #ff1493;
}

/* Completion indicator styles */
.completion-check {
  margin-left: 8px;
  color: #4CAF50;
  font-weight: bold;
  font-size: 14px;
}

.completed {
  color: #4CAF50;
  position: relative;
}

.completed::before {
  content: '';
  position: absolute;
  left: -15px;
  top: 50%;
  width: 4px;
  height: 4px;
  background: #4CAF50;
  border-radius: 50%;
  transform: translateY(-50%);
  box-shadow: 0 0 5px rgba(76, 175, 80, 0.5);
}

/* Focus states for accessibility */
.sidebar-title:focus,
.sidebar-link:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(255, 20, 147, 0.3);
}

/* Custom scrollbar */
.sidebar::-webkit-scrollbar {
  width: 8px;
}

.sidebar::-webkit-scrollbar-track {
  background: #1c1c1c;
}

.sidebar::-webkit-scrollbar-thumb {
  background: rgba(255, 20, 147, 0.2);
  border-radius: 4px;
}

.sidebar::-webkit-scrollbar-thumb:hover {
  background: rgba(255, 20, 147, 0.3);
}

/* Animation for expanding/collapsing sections */
.nested-list {
  transition: max-height 0.3s ease;
}

/* Responsive styles */
@media (max-width: 768px) {
  .sidebar {
    width: 100%;
    height: auto;
    max-height: 300px;
    padding: 10px;
  }

  .sidebar h2 {
    font-size: 18px;
    margin-bottom: 10px;
    padding-bottom: 10px;
  }

  .nested-list {
    padding-left: 10px;
  }

  .sidebar-title,
  .sidebar-link {
    padding: 8px;
    font-size: 14px;
  }

  .section-icon {
    font-size: 10px;
  }
}

/* Button reset for Safari */
.sidebar button {
  -webkit-appearance: none;
  margin: 0;
}

/* Focus visible polyfill support */
.sidebar-title:focus-visible,
.sidebar-link:focus-visible {
  outline: 2px solid #ff1493;
  outline-offset: 2px;
}