.favorites-container {
    flex: 1;
    padding: 2rem;
    background-color: #1a1a1a;
    color: white;
    min-height: calc(100vh - 60px); /* Adjust based on your header height */
    width: 100%;
  }
  
  .favorites-container.empty {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: calc(100vh - 60px); /* Same as non-empty state */
    width: 100%;
    background-color: #1a1a1a;
  }
  
  .favorites-empty {
    text-align: center;
    padding: 3rem;
    background-color: #242424;
    border-radius: 8px;
    max-width: 400px;
    margin: 0 auto;
  }
  
  .favorites-empty h2 {
    color: #ff1493;
    margin-bottom: 1rem;
  }
  
  .favorites-empty p {
    color: #888;
    line-height: 1.5;
  }
  
  .favorites-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid #333;
  }
  
  .favorites-header h2 {
    color: #ff1493;
    margin: 0;
  }
  
  .favorites-count {
    color: #888;
    font-size: 0.9rem;
  }
  
  .favorites-list {
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }
  
  .favorite-item {
    background-color: #242424;
    border-radius: 8px;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
  }
  
  .favorite-item:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  }
  
  .favorite-link {
    width: 100%;
    padding: 1rem;
    background: none;
    border: none;
    color: white;
    cursor: pointer;
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
  }
  
  .favorite-content {
    display: flex;
    gap: 1rem;
    align-items: center;
    flex: 1;
  }
  
  .favorite-icon {
    font-size: 1.5rem;
    min-width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #333;
    border-radius: 8px;
  }
  
  .favorite-info {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  
  .favorite-title {
    font-weight: 500;
    color: white;
    font-size: 1rem;
  }
  
  .favorite-meta {
    display: flex;
    gap: 1rem;
    font-size: 0.8rem;
  }
  
  .favorite-type {
    color: #ff1493;
    text-transform: capitalize;
  }
  
  .favorite-duration {
    color: #888;
  }
  
  .favorite-arrow {
    color: #ff1493;
    font-size: 1.2rem;
    opacity: 0;
    transition: opacity 0.2s ease, transform 0.2s ease;
  }
  
  .favorite-item:hover .favorite-arrow {
    opacity: 1;
    transform: translateX(4px);
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .favorites-container {
      padding: 1rem;
    }
  
    .favorites-list {
      grid-template-columns: 1fr;
    }
  }
  @media (max-width: 768px) {
    .favorites-container {
      padding: 1rem;
    }
  
    .favorites-header {
      flex-direction: column;
      gap: 10px;
      align-items: flex-start;
    }
  
    .favorites-list {
      grid-template-columns: 1fr;
    }
  
    .favorite-item {
      margin-bottom: 10px;
    }
  
    .favorite-content {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .favorite-meta {
      flex-direction: column;
      gap: 5px;
    }
  }