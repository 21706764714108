.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #1a1a1a;
  color: white;
  box-shadow: 0 2px 10px rgba(255, 20, 147, 0.15);
}

.logo-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.logo {
  font-size: 1.8em;
  font-weight: bold;
  color: #ff1493;
  text-shadow: 0 0 10px rgba(255, 20, 147, 0.3);
  letter-spacing: 1px;
}

.sub-logo {
  font-size: 1em;
  color: #888;
  letter-spacing: 2px;
}

nav {
  display: flex;
  gap: 20px;
}

nav .nav-button {
  color: white;
  text-decoration: none;
  background: none;
  border: none;
  padding: 5px 10px;
  font-size: 1em;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
}

nav .nav-button::after {
  content: '';
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 0;
  height: 2px;
  background-color: #ff1493;
  transition: width 0.3s ease;
}

nav .nav-button:hover::after {
  width: 100%;
}

nav .nav-button.active {
  color: #ff1493;
}

nav .nav-button.active::after {
  width: 100%;
}

.user-section {
  display: flex;
  align-items: center;
  gap: 20px;
}

.user-info {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 5px;
}

.username {
  color: #ff1493;
  font-weight: 500;
  text-transform: capitalize;
  font-size: 1.1em;
}

.progress-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.progress-bar {
  width: 150px;
  height: 6px;
  background-color: rgba(255, 20, 147, 0.1);
  border-radius: 10px;
  overflow: hidden;
  border: 1px solid rgba(255, 20, 147, 0.2);
}

.progress {
  height: 100%;
  background: linear-gradient(90deg, #ff1493, #ff69b4);
  box-shadow: 0 0 10px rgba(255, 20, 147, 0.5);
  transition: width 0.3s ease;
}

.progress-text {
  font-size: 0.9em;
  min-width: 40px;
  text-align: right;
  color: #ff1493;
  font-weight: 500;
}

.logout-button {
  padding: 8px 16px;
  background: linear-gradient(45deg, #ff1493, #ff69b4);
  color: white;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 0.9em;
  letter-spacing: 1px;
  box-shadow: 0 2px 10px rgba(255, 20, 147, 0.2);
}

.logout-button:hover {
  background: linear-gradient(45deg, #ff69b4, #ff1493);
  transform: translateY(-1px);
  box-shadow: 0 4px 15px rgba(255, 20, 147, 0.3);
}
@media (max-width: 768px) {
  .header {
    flex-direction: column;
    padding: 10px;
    gap: 10px;
  }

  .logo-container {
    align-items: center;
    text-align: center;
  }

  nav {
    width: 100%;
    justify-content: center;
    flex-wrap: wrap;
  }

  .user-section {
    width: 100%;
    justify-content: center;
    flex-wrap: wrap;
  }

  .user-info {
    align-items: center;
  }

  .progress-container {
    width: 100%;
    justify-content: center;
  }

  .progress-bar {
    width: 200px;
  }
}