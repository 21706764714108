.main-content {
  padding: 30px;
  color: white;
  background-color: #000;
  min-height: calc(100vh - 60px);
}

.content-title {
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
  padding-bottom: 1rem;
  border-bottom: 2px solid rgba(255, 20, 147, 0.1);
}

.highlight {
  color: #ff1493;
  text-shadow: 0 0 10px rgba(255, 20, 147, 0.2);
}

.video-container {
  position: relative;
  width: 100%;
  max-width: 800px;
  margin: 0 auto 30px;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 0 30px rgba(255, 20, 147, 0.1);
}

.video-container::before {
  content: "";
  display: block;
  padding-top: 56.25%;
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}

.lesson-controls {
  display: flex;
  gap: 15px;
  margin: 30px 0;
  justify-content: center;
}

.completion-button,
.favorite-button {
  padding: 12px 24px;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  font-weight: 600;
  font-size: 0.9rem;
  letter-spacing: 0.5px;
  transition: all 0.3s ease;
  text-transform: uppercase;
}

.completion-button {
  background: linear-gradient(45deg, #333, #444);
  color: white;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.completion-button.completed {
  background: linear-gradient(45deg, #4CAF50, #45a049);
  box-shadow: 0 2px 15px rgba(76, 175, 80, 0.3);
}

.favorite-button {
  background: transparent;
  border: 2px solid #ff1493;
  color: #ff1493;
  position: relative;
  overflow: hidden;
}

.favorite-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, transparent, rgba(255, 20, 147, 0.1), transparent);
  transition: left 0.5s ease;
}

.favorite-button:hover::before {
  left: 100%;
}

.favorite-button.favorited {
  background: linear-gradient(45deg, #ff1493, #ff69b4);
  border: none;
  color: white;
  box-shadow: 0 2px 15px rgba(255, 20, 147, 0.3);
}

.completion-button:hover,
.favorite-button:hover {
  transform: translateY(-2px);
}

.additional-content {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin: 30px 0;
  align-items: center;
}

.content-button {
  display: inline-block;
  padding: 12px 24px;
  background: linear-gradient(45deg, #ff1493, #ff69b4);
  color: white;
  text-decoration: none;
  border-radius: 25px;
  text-align: center;
  transition: all 0.3s ease;
  font-weight: 500;
  letter-spacing: 0.5px;
  box-shadow: 0 2px 10px rgba(255, 20, 147, 0.2);
}

.content-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 15px rgba(255, 20, 147, 0.3);
}

.download-link {
  background: none;
  border: none;
  color: #ff1493;
  text-decoration: none;
  transition: all 0.3s ease;
  position: relative;
  padding-bottom: 2px;
  cursor: pointer;
  font-size: inherit;
  font-family: inherit;
}

.download-link::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 1px;
  background: linear-gradient(90deg, #ff1493, #ff69b4);
  transition: width 0.3s ease;
}

.download-link:hover::after {
  width: 100%;
}

.additional-links {
  margin-top: 30px;
  padding: 20px;
  background: rgba(255, 20, 147, 0.05);
  border-radius: 12px;
  border: 1px solid rgba(255, 20, 147, 0.1);
}

.additional-links h3 {
  font-size: 1.2rem;
  margin-bottom: 15px;
  color: #ff1493;
  text-shadow: 0 0 10px rgba(255, 20, 147, 0.2);
}

.text-content {
  max-width: 800px;
  margin: 0 auto;
  line-height: 1.8;
  font-size: 1.1rem;
  padding: 20px;
  background: rgba(255, 255, 255, 0.02);
  border-radius: 12px;
  border: 1px solid rgba(255, 20, 147, 0.1);
}

.text-content h2 {
  color: #ff1493;
  margin-top: 2rem;
  margin-bottom: 1.5rem;
  font-size: 1.8rem;
  text-shadow: 0 0 10px rgba(255, 20, 147, 0.2);
}

.text-content p {
  margin-bottom: 1.5rem;
  color: rgba(255, 255, 255, 0.9);
}

.text-content ul,
.text-content ol {
  margin-bottom: 1.5rem;
  padding-left: 2rem;
}

.text-content li {
  margin-bottom: 0.5rem;
}

.text-content a {
  color: #ff1493;
  text-decoration: none;
  transition: all 0.3s ease;
  position: relative;
  padding-bottom: 2px;
}

.text-content a:hover {
  color: #ff69b4;
}

/* Custom scrollbar */
.main-content::-webkit-scrollbar {
  width: 8px;
}

.main-content::-webkit-scrollbar-track {
  background: #000;
}

.main-content::-webkit-scrollbar-thumb {
  background: rgba(255, 20, 147, 0.2);
  border-radius: 4px;
}

.main-content::-webkit-scrollbar-thumb:hover {
  background: rgba(255, 20, 147, 0.3);
}

/* Responsive styles */
@media (max-width: 768px) {
  .video-container {
    margin: 15px auto;
  }

  .lesson-controls {
    flex-direction: column;
    gap: 10px;
  }

  .completion-button,
  .favorite-button {
    width: 100%;
    padding: 15px;
  }

  .text-content {
    margin: 15px;
    font-size: 1rem;
  }

  .text-content h2 {
    font-size: 1.5rem;
  }

  .additional-links {
    margin: 15px;
  }

  .content-title {
    font-size: 2rem;
    margin-bottom: 1rem;
  }
}
.open-in-drive-button {
  padding: 8px 16px;
  background: linear-gradient(45deg, #ff1493, #ff69b4);
  color: white;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-weight: 500;
  margin: 10px 0;
  display: none; /* Hide by default on desktop */
}

/* Only show the button on mobile devices */
@media (max-width: 768px) {
  .open-in-drive-button {
    display: block;
  }
}