/* App.css */
body {
  margin: 0;
  font-family: Arial, sans-serif;
  background-color: #000;
  color: white;
}

.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #000;
}

.header {
  flex-shrink: 0;
}

.app-content {
  display: flex;
  flex-grow: 1;
}

.sidebar {
  width: 250px;
  flex-shrink: 0;
  background-color: #111;
  color: #fff;
  overflow-y: auto;
}

.main-content {
  flex-grow: 1;
  padding: 2rem;
  background-color: #000;
}

/* Shared styles for video and text content */
.video-container,
.text-content {
  width: 100%;
  max-width: 800px;
  margin-left: 0;
  margin-right: auto;
  margin-bottom: 20px;
}

/* Video container styles */
.video-container {
  position: relative;
}

.video-container::before {
  content: "";
  display: block;
  padding-top: 56.25%; /* 16:9 aspect ratio */
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* Text content styles */
.text-content {
  line-height: 1.6;
  font-size: 1.1rem;
  padding: 20px 0;
  color: white;
  background-color: #000;
  text-align: left;
}

.text-content h2 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  color: #ff1493;
}

.text-content p {
  margin-bottom: 1rem;
}

.text-content ul, .text-content ol {
  margin-bottom: 1rem;
  padding-left: 2rem;
}

.text-content a {
  color: #ff1493;
  text-decoration: none;
}

.text-content a:hover {
  text-decoration: underline;
}

/* Content button styles */
.content-button {
  display: inline-block;
  padding: 10px 20px;
  background-color: #ff1493;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  text-align: center;
  transition: background-color 0.3s ease;
  margin-right: 10px;
  margin-bottom: 10px;
}

.content-button:hover {
  background-color: #ff69b4;
}

/* Specific styles for content buttons within text content */
.text-content .content-button,
.text-content a.content-button {
  color: white !important;
  text-decoration: none;
  font-weight: bold;
  padding: 2px 8px;
  font-size: 0.9em;
  margin: 0 5px;
}

.text-content .content-button:hover,
.text-content a.content-button:hover {
  background-color: #ff69b4;
  text-decoration: none;
}

/* Login container styles */
.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #000;
}

.login-container h1 {
  color: #ff1493;
  margin-bottom: 20px;
}

.login-container input {
  padding: 10px;
  font-size: 16px;
  width: 250px;
  border: 2px solid #ff1493;
  background-color: #111;
  color: white;
  border-radius: 4px;
}

.login-container input:focus {
  outline: none;
  box-shadow: 0 0 5px #ff1493;
}

/* Ensure buttons are aligned to the left */
.lesson-controls,
.additional-content,
.additional-links {
  text-align: left;
  max-width: 800px;
  margin-left: 0;
  margin-right: auto;
}

/* Content title styles */
.content-title {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  text-align: left;
  max-width: 800px;
  margin-left: 0;
  margin-right: auto;
}

.highlight {
  color: #ff1493;
}
@media (max-width: 768px) {
  .app-content {
    flex-direction: column;
  }

  .sidebar {
    width: 100%;
    height: auto;
    max-height: 300px;
    border-right: none;
    border-bottom: 1px solid rgba(255, 20, 147, 0.1);
  }

  .main-content {
    padding: 1rem;
  }

  .content-title {
    font-size: 2rem;
  }

  .text-content {
    padding: 15px;
    font-size: 1rem;
  }
}
.login-button {
  margin-top: 15px;
  padding: 10px 30px;
  background: linear-gradient(45deg, #ff1493, #ff69b4);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  transition: all 0.3s ease;
}

.login-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 15px rgba(255, 20, 147, 0.3);
}